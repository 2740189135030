import { useBlocCoordinator } from "@lib/bloc/hooks"
import { useRoute } from "@lib/router/hooks"
import { isRouteMatch, Route } from "@lib/router/route"
import { safeParseInt } from "@lib/TypeUtil"
import { labelForAsset } from "@model/assets/Asset"
import {
    labelForServiceCode,
    labelForServiceRequestType,
    ServiceRequestType,
} from "@model/serviceRequests/ServiceRequest"
import { labelForUrgency, Urgency } from "@model/Urgency"
import { labelForWorkRequestType, WorkRequestType } from "@model/workRequests/WorkRequest"
import { WorkRequestDetailsEffect } from "@state/workRequests/WorkRequestDetailsScreenBloc"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { AttachmentList } from "@ui/common/form/AttachmentList"
import { CheckBox } from "@ui/common/form/CheckBox"
import { DatePicker, TextInputDatePicker } from "@ui/common/form/DatePicker"
import { CollapsibleFormField, FormField, FormFieldDirection, FormFieldNote } from "@ui/common/form/FormField"
import { FormFieldGroup, FormFieldGroupTheme } from "@ui/common/form/FormFieldGroup"
import { Select, SelectTheme } from "@ui/common/form/Select"
import { TagFormField } from "@ui/common/form/TagFormField"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont, GridGap, GridJustification } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderPreTitle,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { HistoryTable } from "@ui/common/tables/HistoryTable"
import { Tag } from "@ui/common/Tag"
import { DI } from "@ui/DI"
import { Routes } from "@lib/Routes"
import React, { useEffect, useRef, useState } from "react"
import { Strings } from "@lib/Strings"
import { labelForContact } from "@model/contacts/Contact"
import { TagType } from "@model/Tag"
import { Collapsible } from "@ui/common/Collapsible"
import { useIsInIframe, useIsMobile } from "@lib/Hooks"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { PermissionObject, PermissionType } from "@model/user/User"

export function WorkRequestDetailsModal(): JSX.Element | null {
    const route = useRoute()
    const isWorkRequestDetailsRoute = isRouteMatch(route, Routes.WorkRequest) || isRouteMatch(route, Routes.UserWorkRequest)
    const { assetId, workRequestId } = route.data.params
    const onClose = useRef<(() => void) | undefined>(undefined)
    const [hasDataFetchError, setHasDataFetchError] = useState(false)

    return (
        <>
            <Modal
                isVisible={isWorkRequestDetailsRoute}
                size={ModalSize.Large}
                onClose={() => onClose.current?.()}
                useContentTag={true}
                className="modal--work-request-details"
            >
                <WorkRequestDetailsModalContent
                    workRequestId={safeParseInt(workRequestId)}
                    assetId={safeParseInt(assetId)}
                    onCloseRef={onClose}
                    onDataFetchErrorChanged={(value) => setHasDataFetchError(value)}
                    route={route}
                />
            </Modal>
            <FetchDataErrorModal
                isVisible={hasDataFetchError && isWorkRequestDetailsRoute}
                workRequestId={workRequestId}
                assetId={assetId}
            />
        </>
    )
}

function WorkRequestDetailsModalContent({
    workRequestId,
    assetId,
    onCloseRef,
    onDataFetchErrorChanged,
    route,    
}: {
    workRequestId: number
    assetId: number
    onCloseRef: React.MutableRefObject<(() => void) | undefined>
    onDataFetchErrorChanged: (value: boolean) => void
    route: Route
}): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workRequestDetailsModalViewModel())
    onCloseRef.current = () => viewModel.onWorkRequestModalClosed(assetId, route)
    const isMobile = useIsMobile() || useIsInIframe()

    useEffect(() => {
        viewModel.onMounted(workRequestId, assetId)
    }, [])

    useEffect(() => {
        onDataFetchErrorChanged(state.hasDataFetchError)
    }, [state.hasDataFetchError])

    return (
        <ModalContent isLoading={state.isLoading}>
            <ModalHeader>
                <ModalHeaderPreTitle>
                    {state.workRequest ? labelForWorkRequestType(state.workRequest.workRequestType) : "WorkRequest"}
                </ModalHeaderPreTitle>
                <ModalHeaderTitle>
                {state.workRequest !== null && state.workRequest.workRequestType === WorkRequestType.Service ? 
                state.workRequest.workToBePerformed :
                state.workRequest?.step?.label
                }
                    {state.workRequest !== null && state.workRequest.tag !== null && (
                        <Tag tag={state.workRequest.tag} showDayCount={false} />
                    )}
                </ModalHeaderTitle>
                <ModalHeaderSubTitle>{labelForAsset(state.asset)}</ModalHeaderSubTitle>
            </ModalHeader>
            <ModalBody>
                <GridCont gap={GridGap.Medium}>
                    <Grid columns={4}>
                        {state.workRequest?.workRequestType === WorkRequestType.Service ? (
                            <>
                            <FormField label="Service Code">
                            <Select
                                options={[
                                    ...[{ value: "-1", label: "Select Service Code" }],
                                    ...viewModel.state.serviceCodes.map((it) => ({
                                        label: labelForServiceCode(it),
                                        value: it.id.toString(),
                                    })),
                                ]}
                                value={state.form.serviceCodeId ?? ""}
                                onChange={(value) => {
                                    viewModel.serviceCodeChanged(
                                        viewModel.state.serviceCodes.find((it) => it.id === safeParseInt(value)) ?? null
                                    )
                                }}
                                disabled={!viewModel.hasWorkRequestEditPermission() || (state.workRequest?.dismissed ?? false)}
                            />
                            
                        </FormField>
                        { viewModel.getUser().hasAccess( PermissionObject.ServiceQuote, PermissionType.View) ?
                        <FormField>
                       
                            <CheckBox
                                label="Unplanned Repair"
                                tooltip={"Planned repairs are typically significant repairs that are planned for in the asset's life and budgeted for in the annual budget."}
                                onCheckChanged={() => {}}
                                isChecked={state.form.isUnplanned}
                                isEnabled={false}
                                style={{
                                    fontWeight: "bold",
                                    marginTop: "25px",
                                }}
                            />
                        </FormField>
                        : null }
                        </>
                        ) : (
                            <>
                            {state.workRequest?.serviceType === ServiceRequestType.FollowUp ? (<></>) : 
                            (
                                <FormField label="Step">
                                    <Select
                                        theme={SelectTheme.Dark}
                                        options={
                                            !state.workRequest?.schedule
                                                ? []
                                                : state.workRequest?.schedule?.steps
                                                    .sort((a, b) => (a.step > b.step ? 1 : b.step > a.step ? -1 : 0))
                                                    .map((it) => ({
                                                        label: `${it.label}`,
                                                        value: it.id.toString(),
                                                    }))
                                        }
                                        value={state.form.step?.id}
                                        onChange={(value) => viewModel.stepChanged(value)}
                                        disabled={!viewModel.hasWorkRequestEditPermission() || (state.workRequest?.dismissed ?? false) }
                                    />
                                </FormField>
                            )}
                            <FormField label="Service Code">
                            <Select
                                options={[
                                    ...[{ value: "-1", label: "Select Service Code" }],
                                    ...viewModel.state.serviceCodes.map((it) => ({
                                        label: labelForServiceCode(it),
                                        value: it.id.toString(),
                                    })),
                                ]}
                                value={state.form.serviceCodeId ?? ""}
                                onChange={(value) => {
                                    viewModel.serviceCodeChanged(
                                        viewModel.state.serviceCodes.find((it) => it.id === safeParseInt(value)) ?? null
                                    )
                                }}
                                disabled={
                                    !viewModel.hasWorkRequestEditPermission() || (state.workRequest?.dismissed ?? false)
                                }
                            />
                        </FormField>
                        
                            </>
                        )}                        
                        <FormField></FormField>
                         <FormField className="modal-save-buttons-top">
                            <Button
                                type={ButtonType.Text}
                                label="Cancel"
                                onClick={() => viewModel.onWorkRequestModalClosed(assetId,route)}
                            />
                            <Button
                                type={ButtonType.Contained}
                                label="Save"
                                isEnabled={
                                    state.isFormValid && state.effectStatus[WorkRequestDetailsEffect.UpdateWorkRequest].isIdle() 
                                    && ( viewModel.hasWorkRequestEditPermission()) && !(state.workRequest?.dismissed ?? false)
                                }
                                onClick={() => viewModel.onSaveClicked(route)}
                            />
                        </FormField>
                    </Grid>
                    <Grid columns={4}>
                        <FormField label="Urgency">
                            <Select
                                options={Object.values(Urgency).map((urgency) => ({
                                    value: urgency,
                                    label: labelForUrgency(urgency),
                                }))}
                                value={state.form.urgency}
                                onChange={(value) => viewModel.urgencyChanged(value)}
                                disabled={
                                    !viewModel.hasWorkRequestEditPermission() || (state.workRequest?.dismissed ?? false)
                                }
                            />
                        </FormField>
                        <FormField label="Due Date">
                            <TextInputDatePicker
                                value={state.form.dueDate}
                                onChange={(date) => viewModel.dueDateChanged(date)}
                                disabled={
                                    !viewModel.hasWorkRequestEditPermission() || (state.workRequest?.dismissed ?? false)
                                }
                            />
                        </FormField>
                        <FormField label="Due Hour Meter" direction={FormFieldDirection.Column}>
                            <TextInput
                                type={TextInputType.Decimal}
                                min={0}
                                value={state.form.dueHourMeter}
                                onChange={(value) => viewModel.dueHourMeterChanged(value)}
                                isEnabled={
                                    viewModel.hasWorkRequestEditPermission() && !(state.workRequest?.dismissed ?? false)
                                }
                            />
                            {state.asset?.hourMeter != null && state.asset?.hourMeter != 0 ? (
                                <FormFieldNote>
                                    Current: {Strings.formatDecimal(state.asset?.hourMeter ?? 0)}
                                </FormFieldNote>
                            ) : (
                                ""
                            )}
                        </FormField>
                        <FormField label="Due Odometer" direction={FormFieldDirection.Column}>
                            <TextInput
                                type={TextInputType.Integer}
                                min={0}
                                value={state.form.dueOdometer}
                                onChange={(value) => viewModel.dueOdometerChanged(value)}
                                isEnabled={
                                    viewModel.hasWorkRequestEditPermission() && !(state.workRequest?.dismissed ?? false)
                                }
                            />
                            {state.asset?.odometer != null && state.asset?.odometer != 0 ? (
                                <FormFieldNote>
                                    Current: {Strings.formatInteger(state.asset?.odometer ?? 0)}
                                </FormFieldNote>
                            ) : (
                                ""
                            )}
                        </FormField>
                    </Grid>
                    <Grid columns={4}>
                        <FormField label="Estimated Labor Hours">
                            <TextInput
                                type={TextInputType.Float}
                                value={state.form.estimatedLaborHours}
                                onChange={(value) => viewModel.estimatedLaborHoursChanged(value)}
                                min={0}
                                isEnabled={
                                    viewModel.hasWorkRequestEditPermission() && !(state.workRequest?.dismissed ?? false)
                                }
                                onFocus={(event) => event.target.select()}
                            />
                        </FormField>
                        <FormField label="Estimated Parts Cost">
                            <TextInput
                                type={TextInputType.Money}
                                value={state.form.estimatedPartsCost}
                                onChange={(value) => viewModel.estimatedPartsCostChanged(value)}
                                min={0}
                                isEnabled={
                                    viewModel.hasWorkRequestEditPermission() && !(state.workRequest?.dismissed ?? false)
                                }
                                onFocus={(event) => event.target.select()}
                            />
                        </FormField>
                    </Grid>
                    {state.workRequest?.workRequestType === WorkRequestType.Service && (
                        <Grid columns={1}>
                            <FormField label={ state.workRequest.serviceType !== ServiceRequestType.FollowUp ? "Complaint*" : "Work To Be Performed"}>
                                <TextArea
                                    maxLength={250}
                                    value={state.form.workToBePerformed}
                                    onChange={(value) => viewModel.workToBePerformedChanged(value)}
                                    disabled={
                                        !viewModel.hasWorkRequestEditPermission() ||
                                        (state.workRequest?.dismissed ?? false)
                                    }
                                />
                            </FormField>
                        </Grid>
                    )}
                    <Grid columns={1}>
                        <TagFormField
                            tag={state.form.tag}
                            onTagTypeChanged={(type) => viewModel.tagTypeChanged(type)}
                            onReasonChanged={(reason) => viewModel.tagReasonChanged(reason)}
                            formFieldLabel="Current Tag Status"
                            disabled={
                                state.form.tag?.type === state.asset?.tag?.type ||
                                !viewModel.hasWorkRequestEditPermission() ||
                                !viewModel.hasRedYellowTagAddPermission() ||
                                (state.workRequest?.dismissed ?? false)
                            }
                            disableSelection={
                                !viewModel.hasWorkRequestEditPermission() ||
                                !viewModel.hasRedYellowTagAddPermission() ||
                                (state.workRequest?.dismissed ?? false)
                            }
                            disableNoneOption={
                                (state.asset?.tag! && state.asset?.tag?.type !== TagType.None) ||
                                !viewModel.hasWorkRequestEditPermission() ||
                                !viewModel.hasRedYellowTagAddDeletePermission() ||
                                (state.workRequest?.dismissed ?? false)
                            }
                        />
                    </Grid>
                    {state.workRequest?.workRequestType !== WorkRequestType.Service && (
                        <FormField
                            label={
                                <>
                                    <span>Tasks to be Performed</span>
                                </>
                            }
                            direction={FormFieldDirection.Column}
                        >
                            <Grid columns={1} gap={GridGap.Normal}>
                                <Grid columns={3} columnsMd={2} gap={GridGap.Normal}>
                                    {state.form.tasks?.sort((a,b)=> a.label.localeCompare(b.label)).map((task) => (
                                        <FormFieldGroup
                                            key={task.id}
                                            theme={FormFieldGroupTheme.Light}
                                            className="inspection-task"
                                        >
                                            <Grid className="inspection-task-label" columns={1} gap={GridGap.Small}>
                                                <div>{task.label}</div>
                                                <div className="task-description">{task.description}</div>
                                            </Grid>
                                        </FormFieldGroup>
                                    ))}
                                </Grid>
                            </Grid>
                        </FormField>
                    )}
                    <Grid columns={1}>
                        <FormField label="Mechanic's Notes">
                            <TextArea
                                maxLength={2000}
                                value={state.form.notes}
                                onChange={(value) => viewModel.notesChanged(value)}
                                disabled={
                                    !viewModel.hasWorkRequestEditPermission() || (state.workRequest?.dismissed ?? false)
                                }
                            />
                        </FormField>
                    </Grid>
                    {state.workRequest?.workRequestType === WorkRequestType.Service && !isMobile && (
                        <Grid columns={1}>
                            <FormField label="Notify Contacts">
                            <MultiSelect
                                options={viewModel.state.contacts
                                    .filter((it) => !it.deleted || state.form.notifyContacts.includes(it.id))
                                    .map((contact) => ({
                                    key: contact.id,
                                    value: `${contact.id}`,
                                    label: labelForContact(contact),
                                    isChecked: state.form.notifyContacts.includes(contact.id),
                                    checkAll: false,
                                }))}
                                onSelectionChanged={(selection) =>
                                    viewModel.requestNotifyContactChanged(selection.map((contactId) => safeParseInt(contactId)))
                                }
                                />
                            </FormField>
                        </Grid>
                    )}
                    <Grid columns={1}>
                        <AttachmentList
                            attachments={state.form.attachments ?? []}
                            onAttachmentsChanged={(files, formData) => viewModel.attachmentsChanged(files, formData)}
                            onAttachmentDeleteConfirm={(attachment) => viewModel.attachmentDeleted(attachment)}
                            onImageRotate90={(attachment) => viewModel.rotateImage(attachment, 90)}
                            onImageRotate180={(attachment) => viewModel.rotateImage(attachment, 180)}
                            onError={(errors) => viewModel.attachmentError(errors)}
                            disabled={
                                !viewModel.hasWorkRequestEditPermission() || (state.workRequest?.dismissed ?? false)
                            }
                        />
                    </Grid>
                    <Grid columns={1}>                        
                        <CollapsibleFormField label="Repair Request History" isLoading={state.effectStatus[WorkRequestDetailsEffect.FetchWorkRequestHistory].isBusy() } onClick={() =>{
                            if(state.workRequest)
                                viewModel.loadWorkRequestHistory(state.workRequest?.id)
                        }} isCollapsed={true}>
                            <HistoryTable
                                history={state.workRequest?.history ?? []}
                                statusLabelCreator={(status: string) => status}
                            />
                        </CollapsibleFormField>
                        
                    </Grid>
                </GridCont>
            </ModalBody>
            <ModalButtons>
                <Button
                    type={ButtonType.Text}
                    label="Cancel"
                    onClick={() => viewModel.onWorkRequestModalClosed(assetId, route)}
                />
                <Button
                    type={ButtonType.Contained}
                    label="Save"
                    isEnabled={
                        state.isFormValid &&
                        state.effectStatus[WorkRequestDetailsEffect.UpdateWorkRequest].isIdle() &&
                        viewModel.hasWorkRequestEditPermission() &&
                        !(state.workRequest?.dismissed ?? false)
                    }
                    onClick={() => viewModel.onSaveClicked(route)}
                />
            </ModalButtons>
        </ModalContent>
    )
}

function FetchDataErrorModal({
    isVisible,
    assetId,
    workRequestId,
}: {
    isVisible: boolean
    assetId: string
    workRequestId: string
}): JSX.Element {
    const viewModel = DI.workRequestDetailsModalViewModel()

    return (
        <Modal useContentTag={true} isVisible={isVisible} isClosable={false}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Error Loading Service Request</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>There was an error loading the service request.</ModalBody>
                <ModalButtons>
                    <Button
                        label={"Cancel"}
                        type={ButtonType.Text}
                        onClick={() => viewModel.fetchDataErrorCancelClicked(assetId)}
                    />
                    <Button
                        label={"Retry"}
                        onClick={() => viewModel.onMounted(safeParseInt(workRequestId), safeParseInt(assetId))}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
