import { Job } from "@ethossoftworks/job"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { withRouter } from "@lib/router/router"
import { Routes } from "@lib/Routes"
import { Asset } from "@model/assets/Asset"
import { ServiceFormType } from "@model/serviceRequests/ServiceForm"
import { Step } from "@model/Step"
import { TagChange } from "@model/Tag"
import { PermissionObject, PermissionType, User } from "@model/user/User"
import { CreateWorkOrderForm } from "@model/workOrders/CreateWorkOrderForm"
import { WorkOrder } from "@model/workOrders/WorkOrder"
import { AddWorkRequestToWorkOrderForm } from "@model/workRequests/AddWorkRequestToWorkOrderForm"
import { WorkRequest, WorkRequestType } from "@model/workRequests/WorkRequest"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import {
    AssetWorkRequestsBloc,
    AssetWorkRequestsEffect,
    AssetWorkRequestsState,
} from "@state/workRequests/AssetWorkRequestsBloc"
import { ServiceRequestCreationBloc } from "@state/workRequests/ServiceRequestCreationBloc"

type Dependencies = [AssetWorkRequestsState, UserState]
type StateSelection = {
    asset: Asset | null
    workRequests: WorkRequest[]
    workOrders: WorkOrder[]
    workOrderRequests: WorkRequest[]
    isCheckAllChecked: boolean
    selectedWorkRequests: number[]
    isLoading: boolean
    isAddingWorkRequestsToWorkOrder: boolean
    hasDataFetchError: boolean
    workRequestToDismiss: {
        request: WorkRequest
        reason: string
        tagChange: TagChange
        tagReason: string
    } | null
    addToWorkOrderModalVisible: boolean
    createWorkOrderModalVisible: boolean
    user: User
    isRollbackMode: boolean
    rollbackModalVisible: boolean
    createWorkRequestModalVisible: boolean
    stepsInUse: Step[]
}

export class AssetWorkRequestsViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private assetWorkRequestsBloc: AssetWorkRequestsBloc,
        private statusBloc: StatusBloc,
        private userBlock: UserBloc,
        public serviceRequestCreationBloc: ServiceRequestCreationBloc
    ) {
        super([assetWorkRequestsBloc, userBlock])
    }

    transform = ([assetWorkRequestsState, userState]: Dependencies): StateSelection => ({
        asset: assetWorkRequestsState.asset,
        workRequests: assetWorkRequestsState.workRequests.sort(assetWorkRequestCompareSort),
        workOrders: assetWorkRequestsState.workOrders,
        workOrderRequests: assetWorkRequestsState.workOrderRequests,
        workRequestToDismiss: assetWorkRequestsState.workRequestToDismiss,
        selectedWorkRequests: assetWorkRequestsState.selectedWorkRequests,
        isCheckAllChecked:
            assetWorkRequestsState.selectedWorkRequests.length > 0 &&
            assetWorkRequestsState.selectedWorkRequests.length ===
                assetWorkRequestsState.workRequests.filter((x) => !x.workOrderId).length,
        isLoading: assetWorkRequestsState.effectStatus[AssetWorkRequestsEffect.FetchData].isBusy(),
        hasDataFetchError: assetWorkRequestsState.effectStatus[AssetWorkRequestsEffect.FetchData].isError(),
        isAddingWorkRequestsToWorkOrder:
            assetWorkRequestsState.effectStatus[AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder].isBusy(),
        addToWorkOrderModalVisible: assetWorkRequestsState.addToWorkOrderModalVisible,
        createWorkOrderModalVisible: assetWorkRequestsState.createWorkOrderModalVisible,
        user: userState.user,
        isRollbackMode: assetWorkRequestsState.isRollbackMode,
        rollbackModalVisible: assetWorkRequestsState.rollbackModalVisible,
        createWorkRequestModalVisible: assetWorkRequestsState.createWorkRequestModalVisible,
        stepsInUse: assetWorkRequestsState.serviceSchedulesStepsInUse,
    })

    hasInspectionOrPreventativeWorkRequests = (workRequests: WorkRequest[]) => {
        if (
            workRequests.filter(
                (x) =>
                    x.workRequestType == WorkRequestType.Inspection || x.workRequestType == WorkRequestType.Preventative
            ).length > 0
        )
            return true

        return false
    }

    async onMounted(assetId: number) {
        await this.assetWorkRequestsBloc.fetchData(assetId)
    }

    isAssetNotLinkedToSchedule = (stepId: number | undefined) =>
        stepId === undefined ||
        (stepId !== undefined && this.state.stepsInUse.map((x) => x.id).filter((x) => x == stepId).length == 0)

    viewWorkRequestsClicked = withRouter((router) => () => router.navigate(Routes.WorkRequests()))

    createWorkOrder = async (workOrder: CreateWorkOrderForm, assetId?: number) => {
        this.assetWorkRequestsBloc.hideCreateWorkOrderModal()
        const infoMessage = this.statusBloc.enqueueInfoMessage("Creating work order...")
        const outcome = await this.assetWorkRequestsBloc.createWorkOrder(workOrder)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error creating work order")
        }
        this.statusBloc.hideInfoMessage(infoMessage)
        //1 second delay before fetching data to allow time for it to be written to the database. Tested with various times and this is the shortest amount that works.
        if (assetId !== undefined) {
            setTimeout(() => {
                this.onMounted(assetId)
            }, 2000)
        }
    }

    addWorkRequestsToWorkOrder = async (workOrder: WorkOrder, form: AddWorkRequestToWorkOrderForm) => {
        this.assetWorkRequestsBloc.hideAddToWorkOrderModal()
        const infoMessage = this.statusBloc.enqueueInfoMessage("Adding Service requests...")
        const outcome = await this.assetWorkRequestsBloc.addWorkRequestsToWorkOrder(workOrder, form)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error adding service requests")
        }
        this.statusBloc.hideInfoMessage(infoMessage)
    }

    confirmRollBackModal = () => {
        this.assetWorkRequestsBloc.startRollbackMode()
        this.hideRollbackModal()
    }

    cancelRollBackModal = () => {
        this.assetWorkRequestsBloc.stopRollbackMode()
        this.hideRollbackModal()
    }

    showCreateWorkOrderModal = () => this.assetWorkRequestsBloc.showCreateWorkOrderModal()

    hideCreateWorkOrderModal = () => this.assetWorkRequestsBloc.hideCreateWorkOrderModal()

    showCreateWorkRequestModal = () => {
        this.assetWorkRequestsBloc.showCreateWorkRequestModal()
        this.serviceRequestCreationBloc.showModal(ServiceFormType.Request)
    }

    hideCreateWorkRequestModal = () => this.assetWorkRequestsBloc.hideCreateWorkRequestModal()

    showAddToWorkOrderModal = () => this.assetWorkRequestsBloc.showAddToWorkOrderModal()

    hideAddToWorkOrderModal = () => this.assetWorkRequestsBloc.hideAddToWorkOrderModal()

    showRollbackModal = () => this.assetWorkRequestsBloc.showRollbackModal()

    hideRollbackModal = () => this.assetWorkRequestsBloc.hideRollbackModal()

    checkAllCheckboxClicked() {
        this.assetWorkRequestsBloc.toggleCheckAllWorkRequests()
    }

    workRequestCheckboxClicked(id: number) {
        this.assetWorkRequestsBloc.toggleSelectedWorkRequestChanged(id)
    }

    dismissWorkRequestClicked = (workRequest: WorkRequest) =>
        this.assetWorkRequestsBloc.workRequestToDismissChanged(workRequest, {})

    dismissWorkRequestCancelClicked = () => this.assetWorkRequestsBloc.workRequestToDismissChanged(null, {})

    dismissWorkRequestReasonChanged = (workRequest: WorkRequest, reason: string) =>
        this.assetWorkRequestsBloc.workRequestToDismissChanged(workRequest, { reason })

    dismissWorkRequestTagChanged = (workRequest: WorkRequest, change: TagChange) =>
        this.assetWorkRequestsBloc.workRequestToDismissChanged(workRequest, { tagChange: change })

    dismissWorkRequestTagReasonChanged = (workRequest: WorkRequest, tagReason: string) =>
        this.assetWorkRequestsBloc.workRequestToDismissChanged(workRequest, { tagReason })

    dismissWorkRequestConfirmClicked = async () => {
        const data = this.state.workRequestToDismiss
        if (data === null) return

        this.assetWorkRequestsBloc.workRequestToDismissChanged(null, {})
        const infoMessage = this.statusBloc.enqueueInfoMessage("Dismissing service request...")
        const outcome = await this.assetWorkRequestsBloc.dismissWorkRequest(
            data.request,
            data.reason,
            this.userBlock.state.user.id,
            data.tagChange,
            data.tagReason
        )

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error dismissing service request")
        }
        this.statusBloc.hideInfoMessage(infoMessage)
    }

    isAddingWorkRequestsToWorkOrder = () => this.state.isAddingWorkRequestsToWorkOrder

    hasWorkOrderAddPermission = () =>
        this.userBlock.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.Add)

    hasWorkOrderEditPermission = () =>
        this.userBlock.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.Edit)

    hasWorkOrderViewPermission = () =>
        this.userBlock.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.View)

    hasWorkRequestDeletePermission = () =>
        this.userBlock.state.user.hasAccess(PermissionObject.WorkRequest, PermissionType.Delete)

    hasRollbackPermission = () =>
        this.userBlock.state.user.hasAccess(PermissionObject.RollbackWorkOrder, PermissionType.Execute)
}
function assetWorkRequestCompareSort(a: WorkRequest, b: WorkRequest): number {
    // Overdues first
    if (a.isOverdue && !b.isOverdue) return -1
    if (!a.isOverdue && b.isOverdue) return 1

    // Upcomings next
    if (a.isUpcoming && !b.isUpcoming) return -1
    if (!a.isUpcoming && b.isUpcoming) return 1

    // Unassigned next
    if (!a.workOrderId && b.workOrderId) return -1
    if (a.workOrderId && !b.workOrderId) return 1

    // If equal, oldest first
    return b.dateCreated.getTime() - a.dateCreated.getTime()
}
