export type RedirectData = {
    url: string
    isExternal: boolean
}

export function getRedirectUrl(): RedirectData | null {
    const queryParams = new URLSearchParams(window.location.search)
    const url = queryParams.get("redirect-url")

    if (!url) return null

    try {
        const parsedUrl = new URL(url, window.location.origin)
        const isExternal = parsedUrl.origin !== window.location.origin
        return { url, isExternal }
    } catch (error) {
        return { url: "", isExternal: false }
    }
}
