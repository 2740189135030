import { Attachment } from "@model/Attachment"
import { Site } from "@model/site/Site"
import { Tag } from "@model/Tag"
import { Task } from "@model/Task"
import { Urgency } from "@model/Urgency"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkRequestType } from "@model/workRequests/WorkRequest"
import { ServiceCode, ServiceRequestType } from "./ServiceRequest"

export enum ServiceFormType {
    Record = "record",
    Request = "request",
}

export type ServiceRequestForm = {
    type: ServiceRequestType
    workRequestType: WorkRequestType
    assetId: number | null
    serviceCode: ServiceCode | null
    urgency: Urgency
    dueDate: Date | null
    dueHourMeter: string | null
    dueOdometer: string | null
    attachments: Attachment[]
    estimatedLaborHours: string | null
    estimatedLaborCost: string | null
    estimatedPartsCost: string | null
    estimatedTotalCost: string | null
    workToBePerformed: string | null
    specialInstructions: string | null
    tag: Tag | null
    notifyContacts: number[]
    createWorkOrder: boolean
    workOrderId: number | null
    workOrderStatus: WorkOrderStatus | null
    workOrderAssignedTo: number | null
    workOrderSpecialInstructions: string | null
    notes: string | null
    filesId: string | null
    followUpWorkRequestId: number | null
    isUnplanned: boolean
    mechanicsNotes: string | null
    tasks: Task[] | null
}

export type ServiceRecordForm = {
    assetId: number | null
    serviceDate: Date | null
    site: Site | null
    hourMeter: string | null
    odometer: string | null
    attachments: Attachment[]
    workPerformedBy: number[] | null
    laborHours: string | null
    laborCost: string | null
    partsCost: string | null
    totalCost: string | null
    serviceCode: ServiceCode | null
    workPerformed: string | null
    mechanicsNotes: string | null
    externalWorkOrder: string | null
    externalVendor: string | null
    externalInvoice: string | null
    externalNote: string | null
    filesId: string | null
    createManualEntry: boolean
    isUnplanned: boolean
}

export function newServiceRequestForm(): ServiceRequestForm {
    return {
        assetId: null,
        attachments: [],
        dueDate: null,
        dueHourMeter: null,
        dueOdometer: null,
        estimatedLaborHours: null,
        estimatedLaborCost: null,
        estimatedPartsCost: null,
        estimatedTotalCost: null,
        createWorkOrder: false,
        specialInstructions: null,
        notes: null,
        notifyContacts: [],
        serviceCode: null,
        tag: null,
        type: ServiceRequestType.Repair,
        urgency: Urgency.Medium,
        workToBePerformed: null,
        workOrderId: null,
        workOrderStatus: null,
        workOrderAssignedTo: null,
        workOrderSpecialInstructions: null,
        filesId: null,
        followUpWorkRequestId: null,
        isUnplanned: false,
        mechanicsNotes: null,
        tasks: null,
        workRequestType: WorkRequestType.Service,
    }
}

export function newServiceRecordForm(): ServiceRecordForm {
    return {
        assetId: null,
        serviceDate: null,
        site: null,
        hourMeter: null,
        odometer: null,
        attachments: [],
        workPerformedBy: null,
        laborHours: null,
        laborCost: null,
        partsCost: null,
        totalCost: null,
        serviceCode: null,
        workPerformed: null,
        mechanicsNotes: null,
        externalWorkOrder: null,
        externalVendor: null,
        externalInvoice: null,
        externalNote: null,
        filesId: null,
        createManualEntry: false,
        isUnplanned: false,
    }
}
